import { mediaQuery } from "@styles/mediaQueries";
import { colors } from "@util/constants";
import React from "react";
import styled from "styled-components";

interface Props {
  openTab: number;
  onChange: (val: number) => void;
}

const TABS = [
  "Property Information",
  "Photos",
  "Open Homes",
  "Documents",
  "Resources",
  "Correspondence",
  "Expressions of Interest",
];

const TabsMenu = ({ openTab, onChange }: Props) => {
  return (
    <Wrapper>
      <div className="tabs">
        {TABS.map((tab, index) => (
          <button
            onClick={() => onChange(index)}
            key={tab}
            className={`tab ${index === openTab ? "selected" : ""}`}
          >
            <p>{tab}</p>
          </button>
        ))}
      </div>
    </Wrapper>
  );
};

export default TabsMenu;

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 100px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: ${colors.main};

  ${mediaQuery.tabletDown} {
    margin-bottom: 40px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .tabs {
    padding: 30px 0;
    margin: 0 auto;
    width: fit-content;
    display: flex;
    justify-content: center;
  }

  .tab {
    margin: 0;
    padding: 0 20px;

    &:not(:last-child) {
      border-right: 1px solid ${colors.white};
    }

    &.selected {
      p {
        font-weight: bold;
      }
    }

    p {
      white-space: nowrap;
      transition: all 300ms;
      color: ${colors.white};
    }
  }
`;
