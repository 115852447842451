import { BlocksContent } from "@global";
import React from "react";
import { colors } from "@util/constants";
import { em } from "@util/helper";
import moment from "moment";
import styled from "styled-components";
import { Maybe, SanityAccountProperty } from "@graphql-types";
import { mediaQuery } from "@styles/mediaQueries";

const ResourcesTab = ({ data }: { data: Maybe<SanityAccountProperty> | undefined }) => {
  if (!data) return <h4>Loading resources...</h4>;
  return (
    <Wrapper>
      {data?.resourcesHeading && <BlocksContent data={data.resourcesHeading} />}
      <div className="docs-list">
        {data?.resources &&
          data?.resources.map(file => {
            if (!file) return;
            return (
              <FileWrapper href={file?.file?.asset?.url ?? "#"} target="_blank">
                <span className="title">{file.title}</span>
                {file?.file?.asset?._updatedAt ? (
                  <span className="date">
                    {moment(file?.file?.asset?._updatedAt).format("MMM Do YY")}
                  </span>
                ) : null}
              </FileWrapper>
            );
          })}
      </div>
    </Wrapper>
  );
};

export default ResourcesTab;

const Wrapper = styled.div`
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 50px;

  .docs-list {
    margin-top: 40px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);

    ${mediaQuery.tabletDown} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const FileWrapper = styled.a<{ isAdmin?: boolean }>`
  border-radius: 10px;
  border: 1px solid ${colors.main};
  width: 100%;
  padding: 25px 20px;
  text-align: center;
  line-height: 1.2;
  transition: background 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &:hover {
    background: ${colors.main};
    color: ${colors.white};
  }

  span {
    display: inline-block;
    width: 100%;
  }

  .title {
    font-size: ${em(16)};
    font-weight: 600;
  }

  .date {
    font-size: ${em(14)};
  }
`;
