import { OrderWrapper } from "@shared/account/orderComponents";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import TabTitle from "../tabTitle";
import { addCorrespondenceNote, usePropertyContext } from "../utils";
import moment from "moment";
import Button from "@global/button";
import { SanityNote } from "@graphql-types";
import TextField from "@global/textField";
import { GridContainer } from "@util/standard";

const addItemInitialState = {
  title: "",
  content: "",
};

const CorrespondenceTab = () => {
  const { property, loading, setLoading, setProperty } = usePropertyContext();
  const [formVisible, setFormVisible] = useState(false);
  const [inputs, setInputs] = useState<Partial<SanityNote>>(addItemInitialState);
  const refForm = useRef<HTMLDivElement>(null);

  const closeForm = () => {
    setFormVisible(false);
    setInputs(addItemInitialState);
  };

  const openForm = () => {
    setFormVisible(true);
    setTimeout(() => {
      if (refForm.current) {
        refForm.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  };

  const handleAddItem = async () => {
    setLoading(true);
    const res = await addCorrespondenceNote(inputs, property);
    setLoading(false);

    if (res) {
      setProperty(res);
      closeForm();
    }
  };

  return (
    <Wrapper>
      <TabTitle title="">
        <Button onClick={openForm}>Add Note</Button>
      </TabTitle>
      <div className="notes-list">
        {property.notes?.length ? (
          property.notes?.map(note =>
            note ? (
              <OrderWrapper key={note?._key} width="100%" className="note-item">
                <div>
                  <h5>{note.title}</h5>
                  {note.date && <p className="date">{moment(note.date).format("MMM Do YY")}</p>}
                </div>
                <p>{note.content}</p>
              </OrderWrapper>
            ) : null,
          )
        ) : (
          <p>No correspondence / notes</p>
        )}
      </div>
      {formVisible && (
        <div className="add-item" ref={refForm}>
          <hr />
          <h4>Add Note</h4>
          <GridContainer repeat={1} gap="30px">
            <TextField
              value={inputs.title ?? ""}
              onChange={e => setInputs(p => ({ ...p, title: e.target.value }))}
              label="Title"
              id="noteTitle"
            />
            <TextField
              inputType="textarea"
              rows={4}
              value={inputs.content ?? ""}
              onChange={e => setInputs(p => ({ ...p, content: e.target.value }))}
              label="Message"
              id="noteContent"
            />
          </GridContainer>
          <GridContainer
            repeat={2}
            mobileRepeat={2}
            gap="10px"
            width="fit-content"
            margin="30px 0 0"
          >
            <Button disabled={Boolean(loading)} onClick={closeForm} theme="baseOutline">
              Cancel
            </Button>
            <Button disabled={Boolean(loading)} onClick={handleAddItem}>
              Save
            </Button>
          </GridContainer>
        </div>
      )}
    </Wrapper>
  );
};

export default CorrespondenceTab;

const Wrapper = styled.div`
  .notes-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .note-item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      white-space: pre-wrap;
      word-wrap: break-word;
    }

    h5 {
      margin: 0 0 6px;
    }

    .date {
      font-size: 13px;
    }
  }
`;
