import React, { useRef, useState } from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor,
  DragOverlay,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import {
  changePhotoOrder,
  deleteArrayElement,
  uploadPropertyPhotos,
  usePropertyContext,
} from "../utils";
import TabTitle from "../tabTitle";
import DragAndDropInput from "@global/dragAndDrop";
import Button from "@global/button";
import { Delete } from "@util/svg";
import { colors } from "@util/constants";
import { toast } from "react-toastify";
import { Maybe } from "@graphql-types";
import { mediaQuery } from "@styles/mediaQueries";
import Loading from "@global/loading";

const MAX_PHOTOS_TO_UPLOAD = 10;

const SortablePhoto = ({ photo, handleDeleteItem, loading, index }: any) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: photo._key,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none", // Prevent default touch scrolling
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes} // Required for drag handles and accessibility
      {...listeners} // Provides drag functionality
      className="photo"
    >
      {loading && <Loading width="30px" height="30px" baseColor="navy" />}

      <img
        src={photo?.asset?.url ?? ""}
        alt={photo?.altText ?? ""}
        className={loading ? "loading" : ""}
      />
      {photo?.altText && <p>{photo?.altText}</p>}
      <button
        disabled={loading === photo?._key}
        className="delete"
        onClick={() => {
          console.log("onclick", isDragging);
          handleDeleteItem(photo._key);
        }}
      >
        <Delete color="errorRed" />
      </button>
      {index === 0 && <p>Featured</p>}
    </div>
  );
};

const DraggedPhoto = ({
  photo,
}: {
  photo: { _id: string; asset: { url: string } } | undefined;
}) => {
  if (!photo) return null;

  return (
    <div className="dragged-photo">
      <img src={photo.asset.url} alt="" />
    </div>
  );
};

const PhotosTab = () => {
  const { property, setProperty, loading, setLoading } = usePropertyContext();
  const [photos, setPhotos] = useState<File[]>([]);
  const [formVisible, setFormVisible] = useState(false);
  const refForm = useRef<HTMLDivElement>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0.01,
      },
    }),
    useSensor(KeyboardSensor),
  );

  const [items, setItems] = useState(property?.photos ?? []);
  const [activeId, setActiveId] = useState<string | null>(null); // Track the currently dragged item's ID

  const handleChangePhotos = (val: File[]) => {
    const newFiles = [...photos, ...val];
    setPhotos(newFiles.slice(0, MAX_PHOTOS_TO_UPLOAD));
    if (newFiles.length > MAX_PHOTOS_TO_UPLOAD) {
      toast.warn(`You cannot upload more than ${MAX_PHOTOS_TO_UPLOAD} photos per time`);
    }
  };

  const openForm = () => {
    setFormVisible(true);
    setTimeout(() => {
      if (refForm.current) {
        refForm.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const handleUploadPhotos = async () => {
    if (!property._id) return;
    setLoading(true);
    const newPhotos = await uploadPropertyPhotos(photos, property);
    setLoading(false);

    if (newPhotos) {
      setPhotos([]);
      setProperty(p => ({
        ...p,
        photos: newPhotos,
      }));
      setFormVisible(false);
    }
  };

  const handleDeleteItem = async (key: Maybe<string> | undefined) => {
    if (!key) return;
    setLoading(key);
    const res = await deleteArrayElement(key, "photos", property);

    if (res) setProperty(res);
    setItems(res?.photos ?? []);
    setLoading(false);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex((photo: any) => photo._key === active.id);
      const newIndex = items.findIndex((photo: any) => photo._key === over.id);
      const newOrder = arrayMove(items, oldIndex, newIndex);
      setItems(newOrder);

      changePhotoOrder(newOrder, property);
    }
  };

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id); // Set the active item being dragged
  };

  return (
    <Wrapper>
      <TabTitle title="Listing Photos">
        <Button onClick={openForm}>Add Photos</Button>
      </TabTitle>
      <p className="tab-description">
        <i>HomeSell packages generally include professional photography...</i>
      </p>
      {property.photos?.length ? (
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          sensors={sensors}
          onDragStart={handleDragStart}
        >
          <SortableContext
            items={property.photos.map((photo: any) => photo._id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="photos-list">
              {items.map((photo: any, index: number) => {
                return (
                  <>
                    <SortablePhoto
                      key={photo._key}
                      photo={photo}
                      handleDeleteItem={handleDeleteItem}
                      loading={loading}
                      index={index}
                    />
                  </>
                );
              })}
            </div>
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <DraggedPhoto photo={items.find(photo => photo._key === activeId)} />
            ) : null}
          </DragOverlay>
        </DndContext>
      ) : (
        <p>No photos uploaded</p>
      )}
      {formVisible && (
        <div ref={refForm}>
          <hr />
          <h4>Add photos</h4>
          <DragAndDropInput
            onChangeFiles={handleChangePhotos}
            onDelete={val => setPhotos(val)}
            files={photos ?? []}
            multiple
            accept="image/*"
            id="photo-upload"
            disabled={Boolean(loading)}
            name="photo-upload"
            onCancel={() => setPhotos([])}
            onUpload={handleUploadPhotos}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default PhotosTab;

const Wrapper = styled.div`
  .photos-list {
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    display: flex;
    gap: 30px;

    // ${mediaQuery.tabletDown} {
    //   grid-template-columns: repeat(2, 1fr);
    //   gap: 8px;
    // }
  }
  .photo {
    position: relative;
    cursor: grab; /* Gives visual feedback for draggable items */
    width: 100px;

    img {
      width: 100px;
      border-radius: 8px;
      object-fit: cover;
    }
    .delete {
      position: absolute;
      top: 10px;
      right: 10px;

      pointer-events: all;
    }
  }

  .photo:active {
    cursor: grabbing;
  }

  .photo:first-of-type {
    padding: 5px;
    border: 5px solid ${colors.navy};
  }

  .loading-icon {
    position: absolute;
    top: 20px;
    left: 30%;
  }

  .loading {
    opacity: 0.5;
  }
`;
