import React, { useState } from "react";
import { Button, BlocksContent, Link, SelectPackageDialog } from "@global";
import {
  approveListing,
  cancelOrder,
  createPaymentUrl,
  usePropertyContext,
} from "@global/property/utils";
import styled from "styled-components";
import { useAccountPropertyQuery } from "@api";
import { colors } from "@util/constants";
import { capitalize, toDollar } from "@util/helper";
import OrderFeatures from "./orderFeatures";
import { SanityProperty } from "@graphql-types";

interface ActionsProps {
  property: SanityProperty;
  loading: boolean;
  onBuy: () => void;
  onCancel: () => void;
  onPublish: () => void;
}

const OrderActions = ({ property, loading, onBuy, onCancel, onPublish }: ActionsProps) => {
  if (!property.order) return null;
  if (property.order?.status === "unpaid")
    return (
      <div className="buttons">
        <Button loading={loading} onClick={onBuy} theme="white">
          Buy Package
        </Button>
        <Button loading={loading} onClick={onCancel} theme="whiteOutline">
          Cancel / change order
        </Button>
      </div>
    );
  if (!property.publishStatus || property.publishStatus === "pending")
    return (
      <div className="buttons">
        <Button loading={loading} onClick={onPublish} theme="white">
          Publish Property
        </Button>
      </div>
    );
  if (property.publishStatus === "approved")
    return <p>You approved the property to be published</p>;
  if (property.publishStatus === "published") return <p>The property is published</p>;
  return null;
};

const OrderDetails = () => {
  const resourcesData = useAccountPropertyQuery();
  const [packageDialogOpen, setPackageDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featuresOpen, setFeaturesOpen] = useState(false);
  const { property, setProperty } = usePropertyContext();

  const handleCancel = async () => {
    setLoading(true);
    const success = await cancelOrder(property);
    setLoading(false);
    if (success) {
      setProperty(p => ({
        ...p,
        order: null,
      }));
    }
  };

  const handleBuy = async () => {
    setLoading(true);
    const status = await createPaymentUrl(property.order!.total!, property.order!.id!, property);
    setLoading(false);

    if (status === "paid") {
      setProperty(p => ({
        ...p,
        order: { ...property.order!, status: "paid" },
      }));
    }
  };

  const handlePublish = async () => {
    setLoading(true);
    const success = await approveListing(property._id);
    setLoading(false);
    if (success) {
      setProperty(p => ({
        ...p,
        publishStatus: "approved",
      }));
    }
  };

  if (property.order) {
    return (
      <>
        <Wrapper>
          <div>
            <h3>Order Details</h3>
            <p>
              {property.order.items
                ?.slice(0, 8)
                ?.map(el => el?.title)
                .join(", ")}
              ...{" "}
              <button className="view-all" onClick={() => setFeaturesOpen(true)}>
                View All Features
              </button>
            </p>
            <p>
              <b>Tier</b>: {capitalize(property?.order?.tier)}
            </p>
            <p>
              <b>Total</b>: {toDollar(property?.order?.total)}
            </p>
            <p>
              <b>Status</b>: {capitalize(property?.order?.status)}
            </p>
            {property.photosDate && (
              <p>
                <b>Photographer Date</b>: {property.photosDate}
              </p>
            )}
          </div>
          <OrderActions
            loading={loading}
            property={property}
            onCancel={handleCancel}
            onBuy={handleBuy}
            onPublish={handlePublish}
          />
        </Wrapper>
        <OrderFeatures open={featuresOpen} onClose={() => setFeaturesOpen(false)} hidePrices />
      </>
    );
  }

  return (
    <>
      <Wrapper>
        <BlocksContent data={resourcesData?.buyPackageHeading} />
        <div className="buttons">
          <Button onClick={() => setPackageDialogOpen(true)} theme="white">
            Choose a marketing package
          </Button>
          {resourcesData?.buyPackageButtons?.length
            ? resourcesData.buyPackageButtons.map(btn => <Link {...btn} className="button-link" />)
            : null}
        </div>
      </Wrapper>
      <SelectPackageDialog
        onClose={() => setPackageDialogOpen(false)}
        visible={packageDialogOpen}
      />
    </>
  );
};

export default OrderDetails;

const Wrapper = styled.div`
  background-color: ${colors.main};
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;

  h2,
  h3,
  p,
  .block-content * {
    color: ${colors.white};
  }

  p {
    margin-bottom: 12px;
  }

  .buttons {
    margin-top: auto;
    display: flex;
    gap: 10px;
  }

  .view-all {
    margin-top: 8px;
    margin-bottom: 15px;
    font-weight: bold;
    text-decoration: underline;
  }

  .button-link {
    border: 1px solid ${colors.white};
    color: ${colors.white};
    padding: 12px 40px;
    border-radius: 30px;

    &:hover {
      opacity: 0.7;
    }
  }
`;
