import { GridContainer } from "@util/standard";
import { Button, Select, TextField } from "@global";
import { SanityOpenHome } from "@graphql-types";
import React, { useRef, useState } from "react";
import { openHomeTimeOptions, repeatOptions } from "@util/constants";
import TabTitle from "../tabTitle";
import { addOrUpdateOpenHome, deleteArrayElement, usePropertyContext } from "../utils";
import OpenHomesCarousel from "../openHomeCarousel";

const addItemInitialState = {
  startDate: "",
  startTime: "",
  endTime: "",
  repeats: 0,
};

const OpenHomesTab = () => {
  const { property, setProperty, loading, setLoading, isAdmin } = usePropertyContext();
  const [formVisible, setFormVisible] = useState(false);
  const [showRepeats, setShowRepeats] = useState(true);
  const [inputs, setInputs] = useState<Partial<SanityOpenHome>>(addItemInitialState);
  const refForm = useRef<HTMLDivElement>(null);
  const openHomes = property.openHomes ?? [];

  const changeInput = (field: string, value: string) => {
    setInputs((p: any) => ({
      ...p,
      [field]: value,
    }));
  };

  const closeForm = () => {
    setFormVisible(false);
    setInputs(addItemInitialState);
  };

  const openForm = () => {
    setFormVisible(true);
    setShowRepeats(true);
    setTimeout(() => {
      if (refForm.current) {
        refForm.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const handleAddItem = async () => {
    setLoading(true);
    const res = await addOrUpdateOpenHome(inputs, property);
    setLoading(false);

    if (res) {
      setProperty(res);
      closeForm();
    }
  };

  const handleEditItem = (data: SanityOpenHome) => {
    if (!data?._key) return;
    setInputs(data);
    setFormVisible(true);
    setShowRepeats(false);
  };

  const handleDeleteItem = async (data: SanityOpenHome) => {
    if (!data?._key) return;
    setLoading(data._key);
    const res = await deleteArrayElement(data._key, "openHomes", property);
    if (res) setProperty(res);
    setLoading(false);
  };

  return (
    <div>
      <TabTitle title="Open Homes">
        <Button onClick={openForm}>Add Open Home</Button>
      </TabTitle>
      <OpenHomesCarousel
        data={openHomes}
        onEdit={handleEditItem}
        onDelete={handleDeleteItem}
        admin={isAdmin}
        loading={loading}
        isPortal={true}
      />
      {formVisible && (
        <div className="add-item" ref={refForm}>
          <hr />
          <h4>Add Open Home</h4>
          <GridContainer repeat={2} gap="30px">
            <TextField
              value={inputs.startDate ?? ""}
              onChange={e => changeInput("startDate", e.target.value)}
              type="date"
              label="Start Date"
              id="startDate"
            />
            <Select
              value={inputs.startTime ?? ""}
              onChange={e => changeInput("startTime", e.target.value)}
              type="time"
              label="Start Time"
              id="startTime"
              options={openHomeTimeOptions}
            />
            <Select
              value={inputs.endTime ?? ""}
              onChange={e => changeInput("endTime", e.target.value)}
              type="time"
              label="End Time"
              id="endTime"
              options={openHomeTimeOptions}
            />
            {showRepeats && (
              <Select
                value={inputs.repeats ?? ""}
                onChange={e => changeInput("repeats", e.target.value)}
                type="number"
                label="Repeats"
                id="repeats"
                options={repeatOptions}
              />
            )}
          </GridContainer>
          <GridContainer
            repeat={2}
            mobileRepeat={2}
            gap="10px"
            width="fit-content"
            margin="30px 0 0"
          >
            <Button disabled={Boolean(loading)} onClick={closeForm} theme="baseOutline">
              Cancel
            </Button>
            <Button disabled={Boolean(loading)} onClick={handleAddItem}>
              Save
            </Button>
          </GridContainer>
        </div>
      )}
    </div>
  );
};

export default OpenHomesTab;
