import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";

import { Drawer } from "@global";
import { usePropertyContext } from "./utils";
import { capitalize, em, toDollar } from "@util/helper";
import { colors } from "@util/constants";

interface Props {
  open: boolean;
  onClose: () => void;
  hidePrices?: boolean;
}

const OrderFeatures = ({ open, onClose, hidePrices }: Props) => {
  const { property } = usePropertyContext();
  if (!property.order) return null;
  return (
    <Wrapper>
      <Drawer visible={open} onClose={onClose}>
        <h3>Order Features</h3>
        <h5>
          <b>Tier:</b> {capitalize(property.order.tier)}
        </h5>
        {property.order.items?.map(item => {
          return (
            <div className="item">
              <p className="title">{item?.title}</p>
              {item?.variant && (
                <p>
                  <b>Variant: </b> {item.variant.title}
                </p>
              )}
              {item?.quantity && (
                <p>
                  <b>Quantity: </b> {item.quantity}
                </p>
              )}
              {!hidePrices && (
                <p>
                  <b>Price: </b> {toDollar(item?.totalPrice)}
                </p>
              )}
            </div>
          );
        })}
      </Drawer>
    </Wrapper>
  );
};

export default OrderFeatures;

const Wrapper = styled.div`
  .popupContainer {
    height: 100%;
    padding: 40px 0;
  }

  p {
    margin: 0;
  }

  .title {
    font-size: ${em(19, 16)};
  }

  .item {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid ${colors.gridBorder};
  }

  h5 {
    margin-bottom: 30px;
  }
`;
