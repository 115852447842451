import { colors } from "@util/constants";
import { queryCollection } from "@util/firebase";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TabTitle from "../tabTitle";
import { usePropertyContext } from "../utils";
import { mediaQuery } from "@styles/mediaQueries";
import moment from "moment";

const ExpressionOfInterestTab = () => {
  const { property } = usePropertyContext();
  const [data, setData] = useState<any[]>();

  useEffect(() => {
    if (data) return;

    const fetchExpressionsOfInterest = async () => {
      if (!property._id) return;
      const expressions = await queryCollection(
        "expressionOfInterest",
        "propertyId",
        "==",
        property._id,
      );

      setData(expressions ?? []);
    };

    fetchExpressionsOfInterest();
  }, [data]);

  return (
    <Wrapper>
      <TabTitle title="Expressions of interest" />
      {data ? (
        data.length ? (
          data?.map((expression: any) => {
            if (!expression) return null;
            return (
              <StyledExpression key={expression.id}>
                <span>
                  <p>
                    <strong>Date: </strong>
                    {moment(expression.createdAt).format("MMM Do YY - HH:mm")}
                  </p>
                  <p>
                    <strong>Name: </strong>
                    {expression?.firstName} {expression?.lastName}
                  </p>
                  <p>
                    <strong>Company: </strong>
                    {expression?.company}
                  </p>
                  <p>
                    <strong>Email: </strong>
                    <a href={`mailto:${expression?.email}`}>{expression?.email}</a>
                  </p>
                  <p>
                    <strong>Phone: </strong>
                    <a href={`tell:${expression?.phone}`}>{expression?.phone}</a>
                  </p>
                </span>
                <span className="note">
                  <p>
                    Registered for GST in respect of this property transaction:{" "}
                    <b>{expression.gst}</b>
                  </p>
                  <p>
                    NZ, Australian or Singaporean citizen or NZ resident:{" "}
                    <b>{expression.resident}</b>
                  </p>
                  <p>
                    Price offered $: <b>{expression.price}</b>
                  </p>
                  <p>
                    Deposit ($ amount or % amount): <b>{expression.deposit}</b>
                  </p>
                  <p>
                    What are the conditions of sale in respect of this property transaction:{" "}
                    <b>{expression.conditionsOfSale}</b>
                  </p>
                  <p>
                    Other conditions of sale: <b>{expression.conditionsOfSaleOther}</b>
                  </p>
                  <p>
                    Conditions to be satisfied by (expressed as number of working days or a set
                    date): <b>{expression.conditionsToBeSatisfied}</b>
                  </p>
                  <p>
                    Proposed date of Settlement/Possession: <b>{expression.proposedDate}</b>
                  </p>
                </span>
              </StyledExpression>
            );
          })
        ) : (
          <p>No Expressions of interest</p>
        )
      ) : (
        <p>Loading data...</p>
      )}
    </Wrapper>
  );
};

export default ExpressionOfInterestTab;

const Wrapper = styled.div``;

const StyledExpression = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 20px;
  padding: 30px;
  border: 1px solid ${colors.main};
  border-radius: 10px;

  ${mediaQuery.tabletDown} {
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  p {
    margin: 0;
  }
`;
