import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useStore } from "@state/store";
import { Button, Loading, PropertyIcons } from "@global";
import InformationTab from "@global/property/tabs/informationTab";
import PhotosTab from "@global/property/tabs/photosTab";
import OpenHomesTab from "@global/property/tabs/openHomesTab";
import TabsMenu from "@global/property/tabsMenu";
import { PropertyContext } from "@global/property/utils";
import { Container, PageWidth } from "@util/standard";
import { SanityProperty } from "@graphql-types";
import { formatPropertyAddress, getSanityProperty } from "@util/helper";
import styled from "styled-components";
import DocumentsTab from "@global/property/tabs/documentsTab";
import ResourcesTab from "@global/property/tabs/resourcesTab";
import CorrespondenceTab from "@global/property/tabs/correspondenceTab";
import ExpressionOfInterestTab from "@global/property/tabs/expressionOfInterestTab";
import { mediaQuery } from "@styles/mediaQueries";
import { useAccountPropertyQuery } from "@api";
import OrderDetails from "@global/property/orderDetails";

interface Props {
  property: SanityProperty;
  location: any;
}

const AccountProperty = ({ location }: Props) => {
  const [property, setProperty] = useState<SanityProperty>(location.state);
  const [openTab, setOpenTab] = useState(0);
  const { user } = useStore();
  const [loading, setLoading] = useState<string | boolean>(false);
  const resourcesData = useAccountPropertyQuery();

  useEffect(() => {
    if (!user) {
      navigate("/");
      return;
    }
  }, []);

  const getProperty = async (id: string) => {
    const property = await getSanityProperty(id);
    const propertyData = property?.[0];

    if (propertyData) {
      setProperty({ ...propertyData, order: { ...propertyData.order, status: "paid" } });
    } else {
      navigate("/account");
    }
  };

  useEffect(() => {
    if (property) return;
    const params = new URLSearchParams(location.search);
    const merchantRef = params.get("merchant_ref");

    if (merchantRef) {
      const propertyId = merchantRef.split("--")[0];
      getProperty(propertyId);
    } else {
      navigate("/account");
    }
  }, [property]);

  if (!property)
    return (
      <LoadingWrapper>
        <Loading height="30px" width="30px" color="current" />
        <p>Loading Property</p>
      </LoadingWrapper>
    );
  console.log(property);
  return (
    <PropertyContext.Provider
      value={{
        isAdmin: true,
        property,
        loading,
        setLoading,
        setProperty,
      }}
    >
      <Container flexDirection="column" margin="50px auto">
        <PageWidth>
          <Container style={{ gap: "30px" }} margin="0 0 30px">
            <div className="title-area" style={{ flex: 1.5 }}>
              <Button
                linkText="Back to properties"
                theme="baseOutline"
                onClick={() => navigate("/account")}
                width="fit-content"
              />
              <Container width="100%" flexDirection="column" margin="25px 0">
                <h2 className="h4" style={{ marginBottom: "5px" }}>
                  {formatPropertyAddress(property?.address)} | {property?.heading ?? ""}
                </h2>
                <Container margin="0 0 10px 0">
                  <p>Property ID: {property?.referenceID}</p>
                </Container>
                <p>
                  <PropertyIcons
                    bed={property?.details?.bed}
                    scale={0.8}
                    bath={property?.details?.bath}
                  />
                </p>
                <Container
                  backgroundColor="lightGrey"
                  padding="20px"
                  width="fit-content"
                  borderRadius={10}
                >
                  <p>
                    <strong>Listing Status: </strong>
                    {property?.status == "listing" ? "Listed" : "Pending"}
                  </p>
                </Container>
              </Container>
            </div>
            <OrderDetails />
          </Container>
        </PageWidth>
        <TabsMenu openTab={openTab} onChange={val => setOpenTab(val)} />
        <PageWidth>
          <TabsWrapper>
            {
              // Should match the order inside TabsMenu component above
              [
                <InformationTab />,
                <PhotosTab />,
                <OpenHomesTab />,
                <DocumentsTab />,
                <ResourcesTab data={resourcesData} />,
                <CorrespondenceTab />,
                <ExpressionOfInterestTab />,
              ][openTab]
            }
          </TabsWrapper>
        </PageWidth>
      </Container>
    </PropertyContext.Provider>
  );
};

export default AccountProperty;

const LoadingWrapper = styled.div`
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

const TabsWrapper = styled.div`
  hr {
    opacity: 0.4;
    margin: 60px 0;

    ${mediaQuery.tabletDown} {
      margin: 30px 0;
    }
  }
`;
