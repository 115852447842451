import { colors } from "@util/constants";
import { em } from "@util/helper";
import React, { useRef, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { deleteArrayElement, uploadPropertyFiles, usePropertyContext } from "../utils";
import TabTitle from "../tabTitle";
import { mediaQuery } from "@styles/mediaQueries";
import Button from "@global/button";
import DragAndDropInput from "@global/dragAndDrop";
import { toast } from "react-toastify";
import { Maybe, SanityAttachedFile } from "@graphql-types";
import { Delete } from "@util/svg";

const MAX_FILES_TO_UPLOAD = 5;

interface FilesListProps {
  data: Maybe<Maybe<SanityAttachedFile>[]> | undefined;
  loading?: boolean | string;
  onDelete: (key: string) => void;
}

const FilesList = ({ data, loading, onDelete }: FilesListProps) => {
  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(id);
  };

  if (!data?.length) return null;
  return (
    <div className="docs-list">
      {data?.map(file =>
        file ? (
          <FileWrapper
            href={file?.file?.asset?.url ?? "#"}
            target="_blank"
            key={file._key}
            style={{ opacity: file?._key === loading ? 0.4 : 1 }}
          >
            <span className="title">{file.fileName}</span>
            {file?.file?.asset?._updatedAt ? (
              <span className="date">
                {moment(file?.file?.asset?._updatedAt).format("MMM Do YY")}
              </span>
            ) : null}
            <button
              disabled={loading === file._key}
              className="delete"
              onClick={e => handleDelete(e, file?._key ?? "")}
            >
              <Delete color="errorRed" />
            </button>
          </FileWrapper>
        ) : null,
      )}
    </div>
  );
};

const DocumentsTab = () => {
  const { property, setProperty, loading, setLoading } = usePropertyContext();
  const [formVisible, setFormVisible] = useState<string>();
  const [files, setFiles] = useState<File[]>([]);
  const refForm = useRef<HTMLDivElement>(null);

  const handleOpenForm = (val: string) => {
    setFormVisible(val);
    setTimeout(() => {
      if (refForm.current) {
        refForm.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };

  const handleChangeFiles = (val: File[]) => {
    const newFiles = [...files, ...val];
    setFiles(newFiles.slice(0, MAX_FILES_TO_UPLOAD));
    if (newFiles.length > MAX_FILES_TO_UPLOAD) {
      toast.warn(`You cannot upload more than ${MAX_FILES_TO_UPLOAD} files per time`);
    }
  };

  const handleUploadFiles = async () => {
    if (!property._id || !formVisible) return;
    setLoading(true);
    const newPhotos = await uploadPropertyFiles(formVisible, files, property);
    setLoading(false);

    if (newPhotos) {
      setFiles([]);
      setProperty(p => ({
        ...p,
        [formVisible]: newPhotos,
      }));
      setFormVisible(undefined);
    }
  };

  const handleDeleteItem = async (key: Maybe<string> | undefined, field: string) => {
    if (!key) return;
    setLoading(key);
    const res = await deleteArrayElement(key, field, property);
    if (res) setProperty(res);
    setLoading(false);
  };

  return (
    <Wrapper>
      <TabTitle title="Private Property Documents">
        <Button onClick={() => handleOpenForm("privateDocuments")}>Add Document</Button>
      </TabTitle>
      <FilesList
        data={property.privateDocuments}
        onDelete={file => handleDeleteItem(file, "privateDocuments")}
        loading={loading}
      />
      <hr />
      <TabTitle title="Public Property Documents (visible on your property listing page)">
        <Button onClick={() => handleOpenForm("publicDocuments")}>Add Document</Button>
      </TabTitle>
      <FilesList
        data={property.publicDocuments}
        onDelete={file => handleDeleteItem(file, "publicDocuments")}
        loading={loading}
      />
      {formVisible && (
        <div ref={refForm}>
          <hr />
          <h4>Add files</h4>
          <DragAndDropInput
            onChangeFiles={handleChangeFiles}
            onDelete={val => setFiles(val)}
            files={files ?? []}
            multiple
            id="file-upload"
            disabled={Boolean(loading)}
            name="file-upload"
            onCancel={() => setFiles([])}
            onUpload={handleUploadFiles}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default DocumentsTab;

const Wrapper = styled.div`
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 50px;

  .docs-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);

    ${mediaQuery.tabletDown} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

const FileWrapper = styled.a<{ isAdmin?: boolean }>`
  border-radius: 10px;
  border: 1px solid ${colors.main};
  width: 100%;
  padding: 25px 20px;
  text-align: center;
  line-height: 1.2;
  transition: background 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
  position: relative;

  &:hover {
    background: ${colors.main};
    color: ${colors.white};

    .delete {
      opacity: 1;
    }
  }

  span {
    display: inline-block;
    width: 100%;
  }

  .title {
    font-size: ${em(16)};
    font-weight: 600;
    margin-bottom: 10px;
  }

  .date {
    font-size: ${em(14)};
  }

  .delete {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    background-color: ${colors.white};
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${mediaQuery.tabletDown} {
      opacity: 1;
      top: 6px;
      right: 6px;
    }

    svg {
      display: block;
      width: 15px;
      height: 15px;
    }
  }
`;
