import TextField from "@global/textField";
import React, { useState } from "react";
import styled from "styled-components";
import { savePropertyChanges, usePropertyContext } from "../utils";
import { GridContainer } from "@util/standard";
import Select from "@global/select";
import { propertySaleTypes } from "@util/constants";
import TabTitle from "../tabTitle";
import Button from "@global/button";
import { mediaQuery } from "@styles/mediaQueries";

const InformationTab = () => {
  const { property, loading, setLoading, setProperty } = usePropertyContext();
  const [inputs, setInputs] = useState(property ?? {});

  const cancelChanges = (inputsFields: string[]) => {
    const newState = { ...inputs };
    inputsFields.forEach(el => {
      newState[el as keyof typeof property] = property[el as keyof typeof property];
    });
    setInputs(newState);
  };

  const saveChanges = async (inputsFields: string[]) => {
    setLoading(true);
    const res = await savePropertyChanges(inputs, inputsFields, property);
    setLoading(false);

    if (res) {
      setProperty(res);
      setInputs(res);
    }
  };

  const changeInput = (field: string, value: any) => {
    const fieldArr = field.split(".");
    setInputs((p: any) => ({
      ...p,
      [fieldArr[0]]: fieldArr[1]
        ? {
            ...(p[fieldArr[0]] ?? {}),
            [fieldArr[1]]: value,
          }
        : value,
    }));
  };

  return (
    <Wrapper>
      <TabTitle title="Property Description">
        {(inputs.heading !== property.heading ||
          inputs.description !== property.description ||
          inputs.brochureDescription !== property.brochureDescription) && (
          <>
            <Button
              disabled={Boolean(loading)}
              onClick={() => cancelChanges(["heading", "description", "brochureDescription"])}
              theme="baseOutline"
            >
              Cancel
            </Button>
            <Button
              disabled={Boolean(loading)}
              onClick={() => saveChanges(["heading", "description", "brochureDescription"])}
            >
              Save
            </Button>
          </>
        )}
      </TabTitle>
      <TextField
        id="heading"
        onChange={e => changeInput("heading", e.target.value)}
        value={inputs.heading ?? ""}
        label="Listing Title"
        helperText="This is the listing title that will be displayed on homesell.co.nz / trademe etc"
      />
      <TextField
        id="description"
        onChange={e => changeInput("description", e.target.value)}
        value={inputs.description ?? ""}
        inputType="textarea"
        label="About this property"
        rows={10}
        helperText="Describe what you love most about your home"
      />
      <TextField
        id="brochureDescription"
        onChange={e => changeInput("brochureDescription", e.target.value)}
        value={inputs.brochureDescription ?? ""}
        label="Short Description"
        helperText="Enter a short description that will be used for brochure text"
      />
      <hr />
      <div>
        <TabTitle title="How would you like to sell your property?">
          {JSON.stringify(inputs.saleDetails) !== JSON.stringify(property.saleDetails) && (
            <>
              <Button
                disabled={Boolean(loading)}
                onClick={() => cancelChanges(["saleDetails"])}
                theme="baseOutline"
              >
                Cancel
              </Button>
              <Button disabled={Boolean(loading)} onClick={() => saveChanges(["saleDetails"])}>
                Save
              </Button>
            </>
          )}
        </TabTitle>
        <p>
          We generally like to have a conversation with you about pricing, however, if you have a
          price in mind feel free to add it below
        </p>
      </div>
      <GridContainer repeat={2} gap="30px">
        <Select
          label="Type of sale"
          id="saleType"
          value={inputs.saleDetails?.type ?? ""}
          onChange={e => changeInput("saleDetails.type", e.target.value)}
          options={propertySaleTypes}
        />
        {["askingPrice", "enquiriesOver"].includes(inputs.saleDetails?.type ?? "") && (
          <TextField
            inputType="currency"
            currencyProps={{ decimalsLimit: 2, prefix: "$" }}
            onValueChange={val => changeInput("saleDetails.price", val ?? "")}
            value={inputs.saleDetails?.price ?? ""}
            id="askingPrice"
            helperText="The price buyers will see"
            label={inputs.saleDetails?.type === "askingPrice" ? "Asking Price" : "Enquiries Over"}
          />
        )}
        {["auction"].includes(inputs.saleDetails?.type ?? "") && (
          <>
            <TextField
              value={inputs.saleDetails?.date ?? ""}
              onChange={e => changeInput("saleDetails.date", e.target.value)}
              id="saleDate"
              type="date"
              label="Auction Date"
            />
            <TextField
              value={inputs.saleDetails?.time ?? ""}
              onChange={e => changeInput("saleDetails.time", e.target.value)}
              id="auctionTime"
              type="time"
              label="Auction Time"
            />
          </>
        )}
        {["byTender", "deadlineSale"].includes(inputs.saleDetails?.type ?? "") && (
          <TextField
            value={inputs.saleDetails?.date ?? ""}
            onChange={e => changeInput("saleDetails.saleDate", e.target.value)}
            id="saleDate"
            type="date"
            label={
              inputs.saleDetails?.type === "byTender" ? "Tender closing date" : "Deadline date"
            }
          />
        )}
        <TextField
          inputType="currency"
          currencyProps={{ decimalsLimit: 2, prefix: "$" }}
          onValueChange={val => changeInput("saleDetails.expectedSalePrice", val ?? "")}
          value={inputs.saleDetails?.expectedSalePrice ?? ""}
          id="expectedSalePrice"
          helperText="Used for searches - not shown publically"
          label="Expected Sale Price"
        />
      </GridContainer>
    </Wrapper>
  );
};

export default InformationTab;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${mediaQuery.tabletDown} {
    gap: 16px;
  }

  hr {
    opacity: 0.5;
  }
`;
