import React, { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  title: string;
  children?: ReactNode;
}
const TabTitle = ({ title, children }: Props) => {
  return (
    <Wrapper>
      <h4>{title}</h4>
      <div className="right-area">{children}</div>
    </Wrapper>
  );
};

export default TabTitle;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  h4 {
    margin: 0;
  }

  .right-area {
    display: flex;
    gap: 10px;

    button {
      height: fit-content;
      min-height: unset;
      box-sizing: border-box;
      span {
        padding: 10px 30px;
      }
    }
  }
`;
